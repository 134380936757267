import React from 'react';
import Iframe from 'react-iframe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

function Footeralt() {

    const phoneNumber = "+905434017092"; // WhatsApp phone number

    // Function to generate WhatsApp link with pre-filled message
    const openWhatsApp = () => {
        const message = "Merhaba, size ulaşmak istiyorum."; // Pre-filled message
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };

    const handleMailClick = () => {
        window.location.href = "mailto:info@drbengusuozkan.com"; // Mail adresi buraya yazılacak
      };
    

    return (
       <div className='pt-3'>
        <div className="container-xxl">
            <div className="container mx-3 px-2 " style={{ textAlign: 'center' }}>
                <div className="row ">
                    <div className="col">
                        <div className=" text-primary mb-4 animated slideInDown "><h4 className="mb-3" style={{ color: "#d7b27f", fontWeight: '900'}}>İLETİŞİM</h4></div>
                        <p className='textb'><FontAwesomeIcon icon={faMap} color='#d7b27f' size="lg"/>{'  '}Şişli, İstanbul<br />Halaskargazi Mahallesi, Rumeli Caddesi No: 71, İç Kapı No: 6</p>

                        <p className='textb' onClick={handleMailClick}><FontAwesomeIcon icon={faEnvelope} color='#d7b27f' size="lg" /> info@drbengusuozkan.com</p>
                        <p className='textb'>
                            
                            <p onClick={openWhatsApp} style={{ color: 'inherit', textDecoration: 'none' }}>
                                <FontAwesomeIcon icon={faWhatsapp}  color='#d7b27f' style={{ marginRight: '5px'}}  size="lg"/>
                                {phoneNumber}
                            </p>
                        </p>                        
                    </div>
                    <div className="col">
                        <div className=" text-primary mb-4 animated slideInDown "><h4 className="mb-3" style={{ color: "#d7b27f", fontWeight: '900'}}>KONUM</h4></div>
                        <Iframe
                            src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3008.7085485606767!2d28.982818284709747!3d41.05350224276665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab711d8cb2ee5%3A0x1ed6126b57b76070!2zSGFsYXNrYXJnYXppLCBSdW1lbGkgQ2QuIE5vOjcxLCAzNDM3MSDFnmnFn2xpL8Swc3RhbmJ1bA!5e0!3m2!1str!2str!4v1733670470255!5m2!1str!2str%22`}
                            width="100%"
                            height="auto"
                            allowFullScreen=""
                            loading="lazy"
                        ></Iframe>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default Footeralt;
